const moment = require('moment-timezone')

export function isTouchDevice() {
    return 'ontouchstart' in window // works on most browsers
        || navigator.maxTouchPoints // works on IE10/11 and Surface
}

export function inputToRecord(input, excludes, objectReturns) {
    const record = {}

    const keys = Object.keys(input)
    keys.forEach(key => {
        if (!excludes || excludes.indexOf(key) < 0) {
            const value = input[key]
            if (typeof value === typeof {} && value != null) {
                if (Array.isArray(value)) {
                    if (!objectReturns.includes(key)) {
                        record[key] = value.join(',')
                    } else {
                        record[key] = value.map(x => x.id)
                    }
                } else {
                    if (Object.hasOwnProperty.call(value, 'value')) {
                        record[key] = value.value
                    }
                }
            } else {
                record[key] = value
            }
        }
    })

    return record
}
/*
export function getExif(image) {
    EXIF.getData(image, function () {
        var make = EXIF.getTag(this, "DateTimeOriginal");
        var model = EXIF.getTag(this, "Model");
        //var makeAndModel = document.getElementById("makeAndModel");
        //makeAndModel.innerHTML = `${make} ${model}`;
    });

    var img2 = document.getElementById("img2");
    EXIF.getData(img2, function() {
        var allMetaData = EXIF.getAllTags(this);
        var allMetaDataSpan = document.getElementById("allMetaDataSpan");
        allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");
    })
}
*/

function imageStatus(record) {
    let message
    let level = 0
    let value

    if (!record.lastImageDate || moment(record.lastImageDate).isBefore(moment().subtract(1, 'hour'))) {
        message = 'Old Data'
        level = 2
    } else {
        if (!record.estimatedCount || !record.imageCount) {
            message = 'Could not calculate estimated Shots'
            level = 2
        } else {
            if (record.imageCount < 0.7 * record.estimatedCount) {
                message = 'Daily Image Count lower than 70 Percent of predicted'
                level = 2
            } else if (record.imageCount < 0.9 * record.estimatedCount) {
                message = 'Daily Image Count lower than 90 Percent of predicted'
                level = 1
            } else if (record.imageCount > 1.1 * record.estimatedCount) {
                message = 'Daily Image Count greater than 110 Percent of predicted'
                level = 1
            }

            value = (100 / record.estimatedCount) * record.imageCount
        }
    }

    return {
        name: message,
        level,
        value,
    }
}

function timelapseStatus(record) {
    let level = 0
    const message = []

    const result = imageStatus(record)
    if (result.name) {
        message.push(result.name)
        level = result.level
    }

    return { message, level }
}

function internalDiskSpaceStatus(record) {
    let message
    let level = 0
    const value = record.internalDiskSpace

    if (record.internalDiskSpace < 20) {
        message = 'Free Space lower than 20 Percent on Internal Disk'
        level = 1
    } else if (record.internalDiskSpace < 10) {
        message = 'Free Space lower than 10 Percent on Internal Disk'
        level = 2
    }

    return {
        name: message,
        level,
        value,
    }
}

function externalDiskSpaceStatus(record) {
    let message
    let level
    const value = record.internalDiskSpace

    if (record.externalDiskSpace < 20) {
        message = 'Free Space lower than 20 Percent on External Disk'
        level = 1
    } else if (record.externalDiskSpace < 10) {
        message = 'Free Space lower than 10 Percent on External Disk'
        level = 2
    }

    return {
        name: message,
        level,
        value,
    }
}

function diskStatus(record) {
    let level = 0
    const message = []
    const daySize = record.estimatedCount * record.averageSize

    let daysLeft = null

    record.partitions.every(i => {
        const spaceTotal = i.available + i.used
        const spaceLeftPerc = (100 / spaceTotal) * i.available

        // TODO partition name on different OS
        if (i.name === 'D:') {
            daysLeft = Math.floor(i.available / daySize)
        }

        if (moment(i.updatedAt).isBefore(moment().subtract(1, 'day'))) {
            message.push('Old Data')
            level = 2
            return false
        }

        if (spaceLeftPerc < (i.name === 'C:' ? 10 : 20)) {
            message.push(`Free Space lower than 20 Percent on ${i.name === 'Z:' ? 'External Disk' : i.name}`)
            level = 1
        } else if (spaceLeftPerc < 10) {
            message.push(`Free Space lower than 10 Percent on ${i.name === 'Z:' ? 'External Disk' : i.name}`)
            level = 2
            return false
        }

        return true
    })

    if (daysLeft !== null) {
        if (daysLeft < 30) {
            message.push('Days Left lower than 30 days')
            level = 1
        } else if (daysLeft < 7) {
            message.push('Days Left lower than 7 days')
            level = 2
        }
    }

    if (!record.partitions.length) {
        // no data
        message.push('No Disk Data')
        level = 1
    } else if (record.devices.length && (Object.prototype.hasOwnProperty.call(record.devices[0], 'passed') && !record.devices[0].passed)) {
        message.push(`S.M.A.R.T Error on Disk ${record.devices[0].name}`)
        level = 2
    }

    return { message, level }
}

function waterLevelStatus(record) {
    let message
    let level = 0
    const value = record.waterLevel

    if (value < 10) {
        message = 'Tank Level lower than 10 Percent'
        level = 2
    } else if (value < 30) {
        message = 'Tank Level lower than 30 Percent'
        level = 1
    }

    return {
        name: message,
        level,
        value,
    }
}

function wiperStatus(record) {
    let level = 0
    const message = []
    const currentLevel = record.waterLevel

    if (!record.updatedAt || moment(record.updatedAt).isBefore(moment().subtract(1, 'day'))) {
        message.push('Old Data')
        level = 2
    } else {
        const result = waterLevelStatus(record)
        if (result.message) {
            message.push(result.message)
            level = result.level
        }
    }

    return { message, level }
}

function temperatureStatus(record) {
    let message
    let level
    if (record.temperature > 75) {
        message = 'Temperature higher than 75 Degrees'
        level = 2
    } else if (record.temperature > 60) {
        message = 'Temperature higher than 60 Degrees'
        level = 1
    }

    return {
        name: message,
        level,
        value: record.temperature,
    }
}

function humidityStatus(record) {
    let message
    let level
    if (record.humidity > 70) {
        message = 'Temperature higher than 70 Degrees'
        level = 2
    } else if (record.humidity > 55) {
        message = 'Temperature higher than 55 Degrees'
        level = 1
    }

    return {
        name: message,
        level,
        value: record.humidity,
    }
}

function sensorStatus(record) {
    let level = 0
    const message = []

    if (!record.updatedAt || moment(record.updatedAt).isBefore(moment().subtract(1, 'day'))) {
        message.push('Old Data')
        level = 2
    } else if (record.temperature === undefined && record.humidity === undefined) {
        message.push('No Board Data')
        level = 1
    } else {
        if (record.temperature > 75) {
            message.push('Temperature higher than 75 Degrees')
            level = 2
        } else if (record.temperature > 60) {
            message.push('Temperature higher than 60 Degrees')
            level = 1
        }

        if (record.humidity > 70) {
            level = 2
            message.push('Humidity higher than 70 Percent')
        } else if (record.humidity > 55) {
            level = level > 1 ? level : 1
            message.push('Humidity higher than 55 Percent')
        }
    }

    return { message, level }
}

function powerStatus(record) {
    // check for vpn address
    let level = 0
    const message = []

    if (!record.vpn) {
        message.push('No VPN address')
        level = 2
    } else if (!record.updatedAt || moment(record.updatedAt).isBefore(moment().subtract(1, 'day'))) {
        message.push('Old Data')
        level = 2
    } else if (record.routerName.toLowerCase() !== 'teltonika') {
        // eslint-disable-next-line no-lonely-if
        if (record.estimatedVoltage === 24) {
            if (record.voltage < 23 || record.voltage > 29) {
                message.push(`Voltage is severe (${record.voltage}V)`)
                level = 2
            } else if (record.voltage < 23.4 || record.voltage > 28) {
                message.push(`Voltage is critical (${record.voltage}V)`)
                level = 1
            }
        } else if (record.estimatedVoltage === 230) {
            if (record.voltage < 11 || record.voltage > 25) {
                message.push(`Voltage is severe (${record.voltage}V)`)
                level = 1
            }
        }
    }

    return { message, level }
}

function syncStatus(record) {
    let level = 0
    const message = []

    if (!record.syncFolders.length) {
        // no data
        message.push('No Sync Data')
        level = 1
    } else {
        record.syncFolders.every(folder => {
            if (folder.data.v.name.toLowerCase().endsWith('-sync')) {
                if (moment(folder.data.v.stateRead).isBefore(moment().subtract(1, 'hour'))) {
                    message.push('Old Data')
                    level = 2
                    return false
                }

                if (folder.data.v.data.length > 0) {
                    const completion = folder.data.v.data[0].completion.toFixed(2)
                    if (completion < 70) {
                        message.push('Sync Data lower than 70 Percent')
                        level = 1
                        return false
                    }

                    if (completion < 30) {
                        message.push('Sync Data lower than 30 Percent')
                        level = 2
                        return false
                    }
                } else {
                    message.push('No Sync Data for Folder')
                    level = 2
                    return false
                }
            }

            return true
        })
    }

    return { message, level }
}

export function getSystemTypeStatus(type, record) {
    let result

    if (type === 'timelapse') {
        result = timelapseStatus(record)
    } else if (type === 'storage') {
        result = diskStatus(record)
    } else if (type === 'image') {
        result = imageStatus(record)
    } else if (type === 'internalDisk') {
        result = internalDiskSpaceStatus(record)
    } else if (type === 'externalDisk') {
        result = externalDiskSpaceStatus(record)
    } else if (type === 'wiper') {
        result = wiperStatus(record)
    } else if (type === 'sensors') {
        result = sensorStatus(record)
    } else if (type === 'power') {
        result = powerStatus(record)
    } else if (type === 'sync') {
        result = syncStatus(record)
    } else if (type === 'temperature') {
        result = temperatureStatus(record)
    } else if (type === 'humidity') {
        result = humidityStatus(record)
    }

    return result
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`
}

export function formatTime(seconds) {
    let value = seconds
    let timeString = `${value} Second${value > 1 ? 's' : ''}`
    if (seconds === 0) return '0 Seconds'

    const minutes = seconds / 60.0
    if (Math.abs(minutes) > 1) {
        value = Math.trunc(minutes) // minutes.toFixed(dm)
        const rest = Math.round(seconds - (value * 60))
        timeString = `${value} ${value > 1 ? 'Minutes' : 'Minute'}`
        if (rest > 0) {
            timeString += ` ${rest} ${rest === 1 ? 'Second' : 'Seconds'}`
        }
    }
    const hours = minutes / 60.0
    if (Math.abs(hours) > 1) {
        value = Math.trunc(hours) // hours.toFixed(dm)
        const rest = Math.round(minutes - (value * 60))
        timeString = `${value} ${value > 1 ? 'Hours' : 'Hour'}`
        if (rest > 0) {
            timeString += ` ${rest} ${rest === 1 ? 'Minute' : 'Minutes'}`
        }
    }
    const days = hours / 24.0
    if (Math.abs(days) > 1) {
        value = Math.trunc(days) // days.toFixed(dm)
        const rest = Math.round(hours - (value * 24))
        timeString = `${value} ${value > 1 ? 'Days' : 'Day'}`
        if (rest > 0) {
            timeString += ` ${rest} ${rest === 1 ? 'Hour' : 'Hours'}`
        }
    }
    const weeks = days / 7.0
    if (Math.abs(weeks) > 1) {
        value = Math.trunc(weeks)
        const rest = Math.round(days - (value * 7))
        timeString = `${value} ${value > 1 ? 'Weeks' : 'Week'}`
        if (rest > 0) {
            timeString += ` ${rest} ${rest === 1 ? 'Day' : 'Days'}`
        }
    }

    return timeString
}

export function healthCheck(system) {
    const health = {}
    const error = system.errorData
    let level = 0
    /*
    const healthObject = {
        estimatedCount: error.estimatedCount,
        imageCount: error.imageCount,
        averageSize: 0,
        lastImageDate: system.lastImageDate,

        partitions: [],
        devices: [],

        internalDiskSpace: error.diskSpace,
        externalDiskSpace: error.extDiskSpace,

        waterLevel: error.waterLevel,

        temperature: error.temperature,
        humidity: error.humidity,
        voltage: error.voltage,
        estimatedVoltage: system.voltage,
        routerName: system.routerMake,

        syncFolders: [],

        updatedAt: error.updatedAt,
    }
    */

    if (system.computerName && error) {
        let intDisk = false
        if (error.diskSpace) {
            if (error.diskSpace > 80) {
                health.disk = {
                    name: 'Internal Disk Space',
                    value: `${error.diskSpace ? (100 - error.diskSpace).toFixed(2) : '--'} %`,
                    icon: 'harddisk',
                    level: 1,
                }
                level = level < 1 ? 1 : level
                intDisk = true
            }
        } else {
            health.disk = {
                name: 'No Internal Disk Space Data',
                value: '',
                icon: 'harddisk',
                level: 1,
            }
            level = level < 1 ? 1 : level
            intDisk = true
        }

        if (error.extDiskSpace) {
            if (error.extDskSpace < 20) {
                health.extDisk = {
                    name: 'External Disk Space',
                    value: `${error.extDiskSpace ? (100 - error.extDiskSpace).toFixed(2) : '--'} %`,
                    icon: !intDisk ? 'harddisk' : '',
                    level: 1,
                }
                level = level < 1 ? 1 : level
            }
        }

        if (error.humidity > 60) {
            health.humidity = {
                name: 'Humidity',
                value: `${error.humidity ? error.humidity.toFixed(1) : '--'} %`,
                icon: 'water',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (error.temperature > 75) {
            health.temperature = {
                name: 'Temperature',
                value: `${error.temperature ? error.temperature.toFixed(1) : '--'} °C`,
                icon: 'thermometer',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (system.router && error.noVPN) {
            health.vpn = {
                name: 'No VPN URL set',
                value: '',
                icon: 'router-wireless',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (system.pump && (error.waterLevel || error.waterLevel === 0) && error.waterLevel < 20) {
            health.waterLevel = {
                name: 'Water Level',
                value: `${error.waterLevel !== null ? error.waterLevel.toFixed(2) : '--'} %`,
                icon: 'water-pump',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (error.computerPing) {
            const lastPing = moment(error.computerPing)
            const pingDiff = moment().diff(lastPing, 'seconds')
            if (pingDiff > 60 * 60) {
                health.computer = {
                    name: 'Last Computer Ping',
                    value: `${formatTime(pingDiff)} ago`,
                    icon: 'lan-connect',
                    level: 2,
                }
                level = level < 2 ? 2 : level
            } else if (pingDiff > 30 * 60) {
                health.computer = {
                    name: 'Last Computer Ping',
                    value: `${formatTime(pingDiff)} ago`,
                    icon: 'lan-connect',
                    level: 1,
                }
                level = level < 1 ? 1 : level
            }
        } else {
            health.computer = {
                name: 'Last Computer Ping',
                value: 'never',
                icon: 'lan-connect',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (error.routerPing) {
            const lastPing = moment(error.routerPing)
            const pingDiff = moment().diff(lastPing, 'seconds')
            if (pingDiff > 60 * 60) {
                health.router = {
                    name: 'Last Router Ping',
                    value: `${formatTime(pingDiff)} ago`,
                    icon: 'lan-connect',
                    level: 2,
                }
                level = level < 2 ? 2 : level
            } else if (pingDiff > 30 * 60) {
                health.router = {
                    name: 'Last Router Ping',
                    value: `${formatTime(pingDiff)} ago`,
                    icon: 'lan-connect',
                    level: 1,
                }
                level = level < 1 ? 1 : level
            }
        } else {
            health.router = {
                name: 'Last Router Ping',
                value: 'never',
                icon: 'lan-connect',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (!error.syncPercent || error.syncPercent < 90) {
            health.sync = {
                name: 'Synchronized',
                value: `${error.syncPercent ? error.syncPercent.toFixed(1) : '--'} %`,
                icon: 'cloud-off',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }

        if (error.voltage != null && (system.routerMake
            && system.routerMake.toLowerCase() !== 'teltonika')) {
            if (system.voltage === 24) {
                if (error.voltage < 23 || error.voltage > 29) {
                    health.voltage = {
                        name: 'Voltage',
                        value: `${error.voltage !== null ? error.voltage.toFixed(1) : '--'} V`,
                        icon: 'flash',
                        level: 2,
                    }
                    level = level < 2 ? 2 : level
                } else if (error.voltage < 23.4 || error.voltage > 28) {
                    health.voltage = {
                        name: 'Voltage',
                        value: `${error.voltage != null ? error.voltage.toFixed(1) : '--'} V`,
                        icon: 'flash',
                        level: 1,
                    }
                    level = level < 1 ? 1 : level
                }
            } else if (system.voltage === 230) {
                if (error.voltage < 11 || error.voltage > 25) {
                    health.voltage = {
                        name: 'Voltage',
                        value: `${error.voltage != null ? error.voltage.toFixed(1) : '--'} V`,
                        icon: 'flash',
                        level: 1,
                    }
                    level = level < 1 ? 1 : level
                }
            }
        }

        if (!error.wiped) {
            health.wiped = {
                name: 'Wiper',
                value: 'Tank level did not change within a week.',
                icon: 'car-coolant-level',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }
    }

    if (system.lastImageDate) {
        let tz = (system && system.config) ? system.config.timezone : 'utc'
        if (system && system.housingMake === 'Enlaps') {
            tz = 'utc'
        }

        const lastImage = moment.tz(system.lastImageDate, tz)
        const imageDiff = moment().diff(lastImage, 'seconds')

        if (imageDiff > 2 * 60 * 60) {
            health.image = {
                name: 'Last Image',
                value: `${formatTime(imageDiff)} ago`,
                icon: 'image',
                level: 2,
            }
            level = level < 2 ? 2 : level
        } else if (imageDiff > 60 * 60) {
            health.image = {
                name: 'Last Image',
                value: `${formatTime(imageDiff)} ago`,
                icon: 'image',
                level: 1,
            }
            level = level < 1 ? 1 : level
        } else if (imageDiff < 0) {
            health.image = {
                name: 'Last Image',
                value: `${formatTime(imageDiff)} ago`,
                icon: 'image',
                level: 1,
            }
            level = level < 1 ? 1 : level
        }
    } else {
        health.image = {
            name: 'Last Image',
            value: 'never',
            icon: 'image',
            level: 2,
        }
        level = level < 2 ? 2 : level
    }

    if (system.computerName && error) {
        if (error.estimatedCount && error.imageCount) {
            const imagePercent = (100 / error.estimatedCount) * error.imageCount
            if (imagePercent < 90 || imagePercent > 130) {
                health.imageCount = {
                    name: 'Daily Image Count',
                    value: `${imagePercent ? imagePercent.toFixed(2) : '--'} %`,
                    icon: 'image-multiple',
                    level: imagePercent > 130 ? 1 : 2,
                }
                level = (imagePercent > 130 && level <= 1) ? 1 : 2
            }
        } else {
            health.imageCount = {
                name: 'No Daily Image Data found',
                value: '',
                icon: 'image-multiple',
                level: 2,
            }
            level = level < 2 ? 2 : level
        }
    }

    return { health, level }
}
